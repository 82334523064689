import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a479e6f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "is-pulled-right" }
const _hoisted_2 = { class: "custom-card-destinataire has-text-right" }
const _hoisted_3 = { class: "status card-paiement-defaut-carte has-text-right mt-1" }
const _hoisted_4 = { class: "is-flex-direction-column" }
const _hoisted_5 = { class: "custom-card-destinataire" }
const _hoisted_6 = { class: "has-text-grey-light transaction-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-3 shadow-bottom cursor-pointer", { reconversion: _ctx.isReconversion }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.dateFormat(_ctx.transaction.date)), 1),
      _createElementVNode("h5", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.relativeDateFormat(_ctx.transaction.date)) + " ", 1),
        _createVNode(_component_fa_icon, {
          class: _normalizeClass([{
            hide: _ctx.transaction?.pending === true,
          }, "fa-thin"]),
          icon: "check"
        }, null, 8, ["class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", {
        class: _normalizeClass([
          _ctx.transaction.amount.toString().charAt(0) == '-'
            ? 'custom-card-destinataire has-text-danger'
            : 'custom-card-destinataire has-text-success',
        ])
      }, _toDisplayString(_ctx.numericFormat(parseFloat(_ctx.transaction.amount))) + " " + _toDisplayString(_ctx.transaction.currency), 3),
      _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.isReconversion ? _ctx.$gettext("Reconversion") : _ctx.transaction.related), 1),
      _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.transaction.description), 1)
    ])
  ], 2))
}